import React, { useState } from 'react';

import './FirstSeccion.css';
import { useNavigate } from 'react-router-dom';
import { PLAN } from '../../../Consts/BaseUrl';

interface FirstSeccionProps {
}

export const FirstSeccion: React.FC<FirstSeccionProps> = ({

}) => {

  const navigate = useNavigate();

  const handlePlan = () => {
    navigate("/"+PLAN);
    window.scrollTo(0, 0);
  }

  return (
    <div className="landing-section">
        <div className="landing-first-section">
          <div className="landing-first-detail">
            <span className="landing-first-section-text-degradado">Deja de pagar de más:</span>
            <div className="landing-first-text">importa como el 1% de</div>
            <div className="landing-first-text">importadores TOP en</div>
            <div className="landing-first-text">Perú</div>
          </div>
          <div className="landing-first-detail-tablet">
            <div className='landing-first-text'>
              <span className="landing-first-section-text-degradado">Deja de pagar de más: </span>
              importa
            </div>
            <div className="landing-first-text">como el 1% de importadores</div>
            <div className="landing-first-text"> TOP en Perú</div>
          </div>

          <div className='laning-second-detail'>
            <div className="laning-second-subdetail">
                Accede a la misma red y herramientas que ellos utilizan para
                <b className='laning-second-subdetail-bold'> escalar</b> tu negocio con poca inversión.
            </div>
            <div className="laning-second-subdetail-tablet">
                Accede a la misma red y herramientas que ellos
                <div>utilizan para <b className='laning-second-subdetail-bold'> escalar</b> tu negocio con poca inversión.</div>
            </div>
            <div className='landing-first-button' onClick={()=>handlePlan()}>
                Accede <b className='landing-first-button-bold'>AHORA</b>
            </div>
            <div className='landing-first-row'>
                <div className='landing-first-row-white'>4.9/5 basado en 362 reseñas de</div>
                <img className='landing-first-img' src='./images/google-review.png'/>
            </div>
          </div>
        </div>
        <div className="landing-second-section">
          {/* <img className="landing-second-section-img" src="./images/mk-video.png"/> */}
          {/* <video className="landing-second-section-video" controls poster="./images/mk-video.png">
            <source
              src="https://www.youtube.com/embed/2bj_0ru8JgM?si=G2rKPUUXd-EAO55q"
              type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          {/* aad video from youtube*/}
          <iframe
            className='landing-second-section-video'
            width="700"
            height="392"
            src="https://www.youtube.com/embed/2bj_0ru8JgM"
            frameBorder="0"
            allow="autoplay;picture-in-picture;"
            allowFullScreen
          ></iframe>

        </div>

        <div className='landing-first-row landing-first-row-tablet'>
          <div className='landing-first-row-white'>4.9/5 basado en 362 reseñas de</div>
          <img className='landing-first-img' src='./images/google-review.png'/>
        </div>
    </div>
  );
};