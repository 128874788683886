import React, { useState } from 'react';

import './FourSeccion.css';
import { SectionAnimation } from '../../SectionAnimation/SectionAnimation';
import { ToolsConsts } from '../../../Consts/ToolsConsts';

interface FourSeccionProps {
}

export const FourSeccion: React.FC<FourSeccionProps> = ({

}) => {

  return (
    <div className="four-section">
        <div className="four-section-header">
            <div className='four-section-text'>
                Una herramienta. 
                <b className='four-section-text-grandient'> Muchas posibilidades.</b>
            </div>
            <div className='small-subheader four-section-white four-section-text_subtitle'>
                Todo lo que necesitas para escalar tu negocio de 
                importaciones desde cero está aquí.
            </div>
        </div>

        <div className="four-section-tools">
            {ToolsConsts.map((item, index) => (
                <SectionAnimation 
                    key={index}
                    tools={item}
                />
            ))}
        </div>
    </div>
  );
};