import React, { useEffect } from "react";

import './PlanPage.css';
import { PlanSection } from "../../../components/PlanSection/PlanSection";
import { PlanDetailSection } from "../../../components/PlanDetailSection/PlanDetailSection";
import { STORAGE_ASSETS } from "../../../Consts/BaseUrl";

export const PlanPage: React.FC<{}> = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="planPage">
            <div className="planPage-content">
                <div className="big-header planPage-title planPage-w">Elige el plan<span> correcto</span> para ti</div>
                <div className="planPage-t">
                    <div className="big-header planPage-title ">Elige el plan </div>
                    <div className="big-header planPage-title "> <span> correcto </span> para ti</div>
                </div>
                <PlanSection />
                <div className="planPage-title-section">
                    {/* <img src={STORAGE_ASSETS+"/images/png/threeImg.png"} alt="Arkabia" className="planPage-title-img"/> */}
                    <div className="small-subheader planPage-title-section__text"> ¿Cuentas con dudas? Lo resolvemos de inmediato con un asesor</div>
                </div>
                <PlanDetailSection/>
            </div>
        </div>
    )
}