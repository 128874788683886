import React, { useState } from 'react';

import './TenSeccion.css';
import { Questions } from '../../../Consts/Questions';
import { FaqCard } from '../../FaqCard/FaqCard';
import { GroupWhatsapp } from '../../../icons/solid/Group_whatsapp';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';

interface TenSeccionProps {
}

export const TenSeccion: React.FC<TenSeccionProps> = ({

}) => {

    const [activeIndex, setActiveIndex] = useState(0); // Inicializa con el primer elemento activo

    return (
        <div className="ten-section">
            <div className='mega-header ten-seccion-white ten-seccion-title'>¿Tienes preguntas?</div>
            <div className='ten-section-questions'>
                {Questions.map((q, idx) => (
                    <FaqCard 
                        key={idx} 
                        question={q} 
                        index={idx}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                ))}
            </div>
            <div className="ten-section-card">
                <img src={STORAGE_ASSETS+"/images/png/threeImg.png"} alt="Arkabia" className="ten-section-img"/>
                <div className="big-subheader ten-seccion-white">
                    ¿Cuentas con dudas? Lo resolvemos de inmediato con un asesor
                </div>
            </div>
        </div>
    );
};