import React, { useState } from 'react';

import './FiveSeccion.css';
import { STORAGE_ASSETS } from '../../../Consts/BaseUrl';
import { Animation } from '../../Animation/Animation';

interface FiveSeccionProps {
}

export const FiveSeccion: React.FC<FiveSeccionProps> = ({

}) => {

    const handleVideo = () => {
        window.open('https://www.youtube.com/watch?v=-pudIpW2ABc', '_blank');
    }

  return (
    <div className="five-section">
        <div className="five-section-header">
            <div className='big-header five-section-white five-section-title five-section-w'>
                Tenemos más de  
                <b className='five-section-text-grandient'> 18 agencias </b>
                de aduana y carga registradas. 
            </div>
            <div className='big-header five-section-white five-section-title five-section-m'>
                <div>
                    Tenemos más de  
                    <b className='five-section-text-grandient'> 18 agencias </b>
                    de aduana
                </div>
                <div className='big-header five-section-white five-section-title five-section-m'> y carga registradas </div>
            </div>

            <div className='big-header five-section-white five-section-title five-section-m_1'>
                <div>
                    Tenemos más de <b className='five-section-text-grandient'> 18 agencias </b>
                </div>
                <div className='big-header five-section-white five-section-title five-section-m_1'> 
                    de aduana y carga registradas. 
                </div>
            </div>
            
            <div className='small-subheader five-section-white five-section-subtitle'>
                Mira cómo nos aseguramos de que 
                recibas un excelente servicio.
            </div>
        </div>
        
        <Animation 
            animation={"./animations/Desktop/Animación_Loop_Agencias/Animación_Loop_Agencias.json"}
            nameAnimation={"Animación_Loop_Agencias"}/>

        <div className='small-header-medium five-section-button' onClick={()=>handleVideo()}>
            <img className='five-section-button-img' src={STORAGE_ASSETS+'/images/png/youtube-white.png'}/>
            Ver video
        </div>
    </div>
  );
};